.container {
    position: relative;
}

.userIcon {
    background-color: #e5e5e5;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    color: rgba(0,0,0,.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.userIcon:after {
    content: "?";
    font-size: 20px;
    font-weight: 700;
}

.dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: 8px;
    background-color: white;
    padding-inline: 24px;
    padding-block: 8px;
    border: var(--border from global);
    border-radius: 3px;
}

.link {
    display: block;
    padding-block-start: 8px;
}