.container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-block: 16px;
    gap: 16px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-inline: 16px;
}

.body {
    display: flex;
    flex-direction: column;
    padding-inline:  16px;
    gap: 16px;
}

.title {
    font-size: 32px;
}

.iconSettings {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.buttons {
    display: flex;
    gap: 16px;
}