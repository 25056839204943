

.target {
    height: 150px;
    width: 100px;
    border: var(--dashedBorder from global);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    position: relative;
}

.input {
    opacity: 0;
    height: 0;
    max-height: 0;
    min-height:0;
}


.preview {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.editButton {
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 4px;
}