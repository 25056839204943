.container {
    border-block-end: var(--border from global);
    display: flex;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
    padding-inline: 16px;
    position: relative;
}

.container a {
    text-decoration: none;
    color: var(--colorFont from global);
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
    padding-block: 16px;
    box-sizing: border-box;
}

a.active {
    color: var(--colorPrimary from global);
}

.activeIndicator {
    position: absolute;
    bottom: 0;
    height: 4px;
    content: "";
    background-color: var(--colorPrimary from global);
    transition: width .3s cubic-bezier(0.075, 0.82, 0.165, 1), left .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.container a:hover {
    text-decoration: none;
    color: var(--colorFontHover from global);
}