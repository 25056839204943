
.container {
    height: 200px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border: 1px dashed var(--colorBorder from global);
    border-radius: 3px;
}

.plus {
    font-size: 24px;
}