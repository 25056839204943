.container {
    display: flex;
    flex: 1;
    width: 100%;
}

.sideBar {
    display: flex;
    flex-direction: column;
    background-color: #efefef;
    height: 100%;
    width: 300px;
    padding-block: 16px;
    box-sizing: border-box;
    overflow-y: auto;
}

.spaceTaker {
    flex: 1 1 100%;
 
}

.quizIcon {
    height: 100px;
}

.rows {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    min-height: 0;   
}
.row:not(:last-child) {
    border-block-end: var(--border from global);
}

.summary {
    display: flex;
    align-items: start;
    gap: 8px;
}

.rowHeader {
    font-size: 24px;
    padding-block-end: 8px;
}

.action {
    opacity: 0;
}

.row:hover .action, .row:focus-within .action{
    opacity: 1;
}


.body {
    flex: 1;
}