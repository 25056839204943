html, body, :global(#root) {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
