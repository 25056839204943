import React from 'react';
import cx from 'classnames';

import styles from './Review.module.css';

export type ReviewProps = {}

const Review = ({} : ReviewProps) => {
    return <div className={cx(styles.container)}>Review</div>
}

export default Review;