

.container {
    background-color:var(--colorPrimary from global);
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.title {
    font-size: 1em;
    margin-bottom: 0px;
    margin-top: 0;
    font-family: "Homemade Apple";
    text-decoration: none;
    color: white;
}

.login {
    color: white;
    text-decoration: none;
}

.login:hover {
    text-decoration: underline;
}